/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Silnoproudá instalace v bance v Olomouci"}>
        <Column className="pb--30 pt--30" name={"ifpp3bd4bze"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--36 w--300" content={"Elektroinstalace banka Olomouc"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" content={"Jednalo se o nově vznikající pobočku banky. V rámci naší firmy jsme zde prováděli kompletní silnoproudé rozvody, osvětlení, dodání a zapojení nového rozvaděče a přípravu pro slaboproudé rozvody."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"yvft8nqfrj"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/558e1c288c5b4b54bcc075c71a341ee1_s=1400x_.jpg 1400w"} position={{"x":"-32.82767624020888%","y":"-35.81723237597912%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/eba753eb0e8b4c22bb79dc7868807557_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/eba753eb0e8b4c22bb79dc7868807557_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/eba753eb0e8b4c22bb79dc7868807557_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/eba753eb0e8b4c22bb79dc7868807557_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/eba753eb0e8b4c22bb79dc7868807557_s=1400x_.jpg 1400w"} position={{"x":"-6.117493472584854%","y":"-4.699738903394259%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/d01ebd400d8a4a17a2ff37ab63087c7a_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/d01ebd400d8a4a17a2ff37ab63087c7a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/d01ebd400d8a4a17a2ff37ab63087c7a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/d01ebd400d8a4a17a2ff37ab63087c7a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/d01ebd400d8a4a17a2ff37ab63087c7a_s=1400x_.jpg 1400w"} position={{"x":"-40.30548302872063%","y":"-15.926892950391645%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/e362c1ae379a4e8f8c5ce644fc6608c8_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/e362c1ae379a4e8f8c5ce644fc6608c8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/e362c1ae379a4e8f8c5ce644fc6608c8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/e362c1ae379a4e8f8c5ce644fc6608c8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/e362c1ae379a4e8f8c5ce644fc6608c8_s=1400x_.jpg 1400w"} position={{"x":"-3.8224543080939943%","y":"-21.360313315926895%"}}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/11759/dfcd25f8b963454e8519bdb95c62d7d5_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/dfcd25f8b963454e8519bdb95c62d7d5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/dfcd25f8b963454e8519bdb95c62d7d5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/dfcd25f8b963454e8519bdb95c62d7d5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/dfcd25f8b963454e8519bdb95c62d7d5_s=1400x_.jpg 1400w"} position={{"x":"-2.955613577023499%","y":"-52.66057441253264%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/4097a077505e491a9d267404a0b93e7b_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/4097a077505e491a9d267404a0b93e7b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/4097a077505e491a9d267404a0b93e7b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/4097a077505e491a9d267404a0b93e7b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/4097a077505e491a9d267404a0b93e7b_s=1400x_.jpg 1400w"} position={{"x":"-37.69451697127937%","y":"-64.96083550913838%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/75b988921fca4b569830341a7d15ef0e_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/75b988921fca4b569830341a7d15ef0e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/75b988921fca4b569830341a7d15ef0e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/75b988921fca4b569830341a7d15ef0e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/75b988921fca4b569830341a7d15ef0e_s=1400x_.jpg 1400w"} position={{"x":"0%","y":"-29.765013054830284%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}